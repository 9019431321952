"use strict";

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const returnClientsRoot = (to, from, next) => {
    return next({ "name": "shop_list" });
};

const router = new Router({
    "mode": "history",
    "base": process.env.BASE_URL,
    "routes": [
        {
            "path": "/",
            "component": () => import("./layouts/sp.vue"),
            "redirect": { "name": "shop_list" },
            "children": [
                {
                    "path": "/shop",
                    "name": "shop_list",
                    "component": () => import("./views/ShopList.vue"),
                },
                {
                    "path": "/input",
                    "name": "input",
                    "component": () => import("./views/Input.vue"),
                },
                {
                    "path": "/confirm",
                    "name": "confirm",
                    "component": () => import("./views/Confirm.vue"),
                },
                {
                    "path": "/finish",
                    "name": "finish",
                    "component": () => import("./views/Finish.vue"),
                },
                {
                    "path": "/law",
                    "name": "law",
                    "component": () => import("./views/Law.vue"),
                },
                {
                    "path": "/login",
                    "name": "login",
                    "component": () => import("./layouts/LogIn.vue"),
                },
            ],
        },
        {
            "path": "*",
            "beforeEnter": returnClientsRoot,
        },
    ],
    scrollBehavior() {
        return {
            "x": 0,
            "y": 0,
        };
    },
});

export default router;
